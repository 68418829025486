<template>
  <v-data-table
    :headers="headers"
    :items="invoices"
    :loading="loading"
    classes="elevation-1 combined-invoices-table"
    hide-actions
  >
    <template
      v-slot:headers="props"
      v-if="filters !== null"
    >
      <tr>
        <th
          v-for="(header, key) in props.headers"
          :key="key"
          :class="[
            'column',
            header.customSortable && 'sortable',
            header.customSortable &&
              (filters.sortDir === 'desc' ? 'desc' : 'asc'),
            header.value === filters.sort ? 'active' : '',
          ]"
          @click="changeTableSort(header)"
        >
          <v-icon
            v-if="header.customSortable"
            small
            color="white"
          >arrow_upward</v-icon
          >
          {{ header.text }}
        </th>
      </tr>
    </template>
    <template v-slot:items="props">
      <tr :class="`invoice-row ${props.item.active ? '' : 'inactive-row'}`">
        <td v-if="selectableMany">
          <v-checkbox
            v-if="props.item.active"
            v-model="props.item.isSelected"
            class="ml-3"
            hide-details
          />
        </td>
        <td v-else-if="selectableOne">
          <v-radio-group
            v-if="props.item.active"
            v-model="invoiceForPay">
            <v-radio
              :value="{id: props.item.id, type: props.item.type }"
              class="mt-4"
              hide-details
              hide-messages
            />
          </v-radio-group>
        </td>
        <td>{{ props.item.id }}</td>
        <td>
          {{ props.item.invoicedAt }}
          <span
            v-if="!props.item.active"
            class="make-ellipse">
            (credit sent)
          </span>
        </td>
        <td>{{ props.item.number }}</td>
        <td>{{ props.item.passenger && (props.item.passenger.company || props.item.passenger.name) }}</td>
        <td>
          <div class="d-flex align-items-center">
            <v-menu
              bottom
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="ma-2 white--text"
                  color="secondary"
                  v-on="on"
                >
                  {{ props.item.rides && Object.keys(props.item.rides).length }}
                  <v-icon
                    right
                    dark
                  >
                    toc
                  </v-icon>
                </v-btn>
              </template>
              <v-card
                class="pa-3"
                max-width="300">
                <router-link
                  v-for="(ride, key) in Object.values(props.item.rides)"
                  :key="key"
                  :to="`/dashboard/ride-overview?rideId=${ride.id}`"
                  target="_blank"
                  class="mr-2"
                >
                  {{ ride.id }}
                </router-link>
              </v-card>
            </v-menu>
          </div>
        </td>
        <td>{{ props.item.price.vatExcl }}</td>
        <td>{{ props.item.price.vat }}</td>
        <td>{{ props.item.price.vatIncl }}</td>
        <td>
          <v-tooltip
            v-if="!props.item.draft && props.item.closedAt"
            top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="success"
                class="c-pointer"
                dark
                v-on="on"
              >
                check
              </v-icon>
            </template>
            <span>Closed at: {{ momentDateTime(props.item.closedAt) }}</span>
          </v-tooltip>
          <v-icon
            v-else
            v-bind="attrs"
            color="error"
            class="c-pointer"
            dark
            v-on="on"
          >
            close
          </v-icon>
        </td>
        <td>
          <span class="make-ellipse">{{ props.item.sentAt && momentDateTime(props.item.sentAt) }}</span>
        </td>
        <td>{{ props.item.eo && (props.item.eo.syncedAt ? momentDateTime(props.item.eo.syncedAt) : props.item.eo.error) }}</td>
        <td>
          <invoice-pdf
            :invoice="props.item"
            :link-icon="true"
          />
        </td>
        <td>
          <v-dialog
            v-if="invoiceEdit && props.item.draft && props.item.active"
            v-model="props.item.invoiceEditDialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                color="primary"
                dark
                small
                v-on="on"
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h lighten-2">
                Invoice edit
              </v-card-title>

              <v-card-text>
                <v-datetime-picker
                  v-model="props.item.invoicedAt"
                  :time-picker-props="{ format: '24hr' }"
                  :date-picker-props="{ firstDayOfWeek: 1 }"
                  date-format="dd.MM.yyyy"
                  time-format="HH:mm"
                />

                <!-- <v-text-field
                  v-model="props.item.invoicedAt"
                  label="Invoiced at"
                  type="datetime-local"
                /> -->
              </v-card-text>

              <v-card-actions>
                <v-spacer/>
                <v-btn
                  color="primary"
                  text
                  @click="invoiceEdit(props.item)"
                >
                  save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            v-if="invoiceDelete && !props.item.sentAt"
            color="error"
            dark
            small
            @click="() => { invoiceDelete(props.item) }"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import Request from '../../helpers/request'
import InvoicePdf from 'Components/Invoice/InvoicePdf'
import CombinedInvoices from '../../helpers/combined-invoices'

export default {
  components: { InvoicePdf },
  mixins: [Request, CombinedInvoices],
  props: {
    invoices: {
      type: Array,
      default: () => []
    },
    selectableMany: {
      type: Boolean,
      default: () => false
    },
    selectableOne: {
      type: Boolean,
      default: () => false
    },
    filters: {
      type: Object,
      default: null
    },
    changeTableSort: {
      type: Function,
      default: () => {}
    },
    invoiceEdit: {
      type: Function,
      default: null
    },
    invoiceDelete: {
      type: Function,
      default: null
    },
    updateInvoiceForPay: {
      type: Function,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    invoiceForPay: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          align: 'center',
          sortable: false,
          customSortable: true,
          value: 'id'
        },
        { text: 'Invoiced At', align: 'center', sortable: false },
        { text: 'Number', align: 'center', sortable: false },
        { text: 'Customer', align: 'center', sortable: false },
        { text: 'Rides', align: 'center', sortable: false },
        { text: 'VAT excl.', align: 'center', sortable: false },
        { text: 'VAT.', align: 'center', sortable: false },
        { text: 'VAT incl.', align: 'center', sortable: false },
        { text: 'Sent', align: 'center', sortable: false },
        { text: 'Sent at', align: 'center', sortable: false },
        { text: 'Synced at', sortable: false },
        { text: 'PDF', sortable: false },
        { text: '', sortable: false }
      ]
    }
  },
  watch: {
    invoiceForPay: {
      handler () {
        if (this.updateInvoiceForPay) {
          this.updateInvoiceForPay(this.invoiceForPay)
        }
      },
      deep: true
    }
  },
  beforeMount () {
    if (this.selectableMany || this.selectableOne) {
      this.headers.unshift({ text: 'Select', align: 'center', sortable: false })
    }
  }
}

</script>

<style lang="scss">
.combined-invoices-table table.v-table {
  min-width: 100% !important;
  width: max-content !important;
  max-width: initial !important;

  th {
    text-align: center;
    background-color: #424242 !important;
    color: white !important;
  }
}

.invoice-row {
  td {
    text-align: center;
  }
}

.inactive-row {
  background: #dededf !important;
  opacity: 0.5;
}
</style>
