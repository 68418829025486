import request from './request'

export default {
  data () {
    return {}
  },
  mixins: [request],
  methods: {
    // {
    //   passenger: int, // required
    //   brand: int // required
    //   rides: [int, int, ...] // required, non empty
    // }
    async createCombinedInvoice (data) {
      let body = {
        data
      }

      return this.request('POST', `/combined-invoices`, body)
    },
    async getCombinedInvoices (params = {}) {
      // Sorts: id (default is desc), number, invoicedAt, expiredAt, createdAt
      // Filters:
      //   - ?search=string, ?invoicedAtFrom=date, ?invoicedAtTo=date, ?passenger=id
      //   - ?brands=1,2,3
      //   - default: ?deleted, createdAtFrom, etc
      return this.request('GET', `/combined-invoices`, { params })
    },
    async getCombinedInvoice (id) {
      return this.request('GET', `/combined-invoices/${id}`, {})
    },
    async deleteCombinedInvoice (id) {
      return this.request('DELETE', `/combined-invoices/${id}`, {})
    },
    async creditCombinedInvoicesBulk (invoices) {
      this.loading = true
      const bodyData = invoices.map((item, index) => ({
        key: index + 1,
        id: item.id
      }))

      return this.request('POST', `/combined-invoices/bulk/credit`, { data: bodyData })
    },
    async debitCombinedInvoicesBulk (invoices) {
      this.loading = true
      const bodyData = invoices.map((item, index) => ({
        key: index + 1,
        id: item.id
      }))

      return this.request('POST', `/reverse-invoices/bulk/debit`, { data: bodyData })
    },
    async sendCombinedInvoicesBulk (invoices, isReverse = false) {
      this.loading = true
      const bodyData = invoices.map((item, index) => ({
        key: index + 1,
        id: item.id
      }))

      return this.request('POST', isReverse ? `/reverse-invoices/bulk/send` : `/combined-invoices/bulk/send`, { data: bodyData })
    },
    async deleteCombinedInvoicesBulk (invoices, isReverse = false) {
      this.loading = true
      const bodyData = invoices.map((item, index) => ({
        key: index + 1,
        id: item.id
      }))

      return this.request('DELETE', isReverse ? `/reverse-invoices/bulk` : `/combined-invoices/bulk`, { data: bodyData })
    },
    async reopenCombinedInvoicesBulk (invoices) {
      this.loading = true
      const bodyData = invoices.map((item, index) => ({
        key: index + 1,
        id: item.id
      }))

      return this.request('POST', `/combined-invoices/bulk/reopen`, { data: bodyData })
    },
    async reopenReverseInvoicesBulk (invoices) {
      this.loading = true
      const bodyData = invoices.map((item, index) => ({
        key: index + 1,
        id: item.id
      }))

      return this.request('POST', `/reverse-invoices/bulk/reopen`, { data: bodyData })
    }
  }
}
