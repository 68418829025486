var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"loading":_vm.loading,"classes":"elevation-1 combined-invoices-table","hide-actions":""},scopedSlots:_vm._u([(_vm.filters !== null)?{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header,key){return _c('th',{key:key,class:[
          'column',
          header.customSortable && 'sortable',
          header.customSortable &&
            (_vm.filters.sortDir === 'desc' ? 'desc' : 'asc'),
          header.value === _vm.filters.sort ? 'active' : '' ],on:{"click":function($event){return _vm.changeTableSort(header)}}},[(header.customSortable)?_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("arrow_upward")]):_vm._e(),_vm._v("\n        "+_vm._s(header.text)+"\n      ")],1)}),0)]}}:null,{key:"items",fn:function(props){return [_c('tr',{class:("invoice-row " + (props.item.active ? '' : 'inactive-row'))},[(_vm.selectableMany)?_c('td',[(props.item.active)?_c('v-checkbox',{staticClass:"ml-3",attrs:{"hide-details":""},model:{value:(props.item.isSelected),callback:function ($$v) {_vm.$set(props.item, "isSelected", $$v)},expression:"props.item.isSelected"}}):_vm._e()],1):(_vm.selectableOne)?_c('td',[(props.item.active)?_c('v-radio-group',{model:{value:(_vm.invoiceForPay),callback:function ($$v) {_vm.invoiceForPay=$$v},expression:"invoiceForPay"}},[_c('v-radio',{staticClass:"mt-4",attrs:{"value":{id: props.item.id, type: props.item.type },"hide-details":"","hide-messages":""}})],1):_vm._e()],1):_vm._e(),_c('td',[_vm._v(_vm._s(props.item.id))]),_c('td',[_vm._v("\n        "+_vm._s(props.item.invoicedAt)+"\n        "),(!props.item.active)?_c('span',{staticClass:"make-ellipse"},[_vm._v("\n          (credit sent)\n        ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(props.item.number))]),_c('td',[_vm._v(_vm._s(props.item.passenger && (props.item.passenger.company || props.item.passenger.name)))]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("\n                "+_vm._s(props.item.rides && Object.keys(props.item.rides).length)+"\n                "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("\n                  toc\n                ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-3",attrs:{"max-width":"300"}},_vm._l((Object.values(props.item.rides)),function(ride,key){return _c('router-link',{key:key,staticClass:"mr-2",attrs:{"to":("/dashboard/ride-overview?rideId=" + (ride.id)),"target":"_blank"}},[_vm._v("\n                "+_vm._s(ride.id)+"\n              ")])}),1)],1)],1)]),_c('td',[_vm._v(_vm._s(props.item.price.vatExcl))]),_c('td',[_vm._v(_vm._s(props.item.price.vat))]),_c('td',[_vm._v(_vm._s(props.item.price.vatIncl))]),_c('td',[(!props.item.draft && props.item.closedAt)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"c-pointer",attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v("\n              check\n            ")])]}}],null,true)},[_c('span',[_vm._v("Closed at: "+_vm._s(_vm.momentDateTime(props.item.closedAt)))])]):_c('v-icon',_vm._g(_vm._b({staticClass:"c-pointer",attrs:{"color":"error","dark":""}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v("\n          close\n        ")])],1),_c('td',[_c('span',{staticClass:"make-ellipse"},[_vm._v(_vm._s(props.item.sentAt && _vm.momentDateTime(props.item.sentAt)))])]),_c('td',[_vm._v(_vm._s(props.item.eo && (props.item.eo.syncedAt ? _vm.momentDateTime(props.item.eo.syncedAt) : props.item.eo.error)))]),_c('td',[_c('invoice-pdf',{attrs:{"invoice":props.item,"link-icon":true}})],1),_c('td',[(_vm.invoiceEdit && props.item.draft && props.item.active)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("\n                edit\n              ")])],1)]}}],null,true),model:{value:(props.item.invoiceEditDialog),callback:function ($$v) {_vm.$set(props.item, "invoiceEditDialog", $$v)},expression:"props.item.invoiceEditDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h lighten-2"},[_vm._v("\n              Invoice edit\n            ")]),_c('v-card-text',[_c('v-datetime-picker',{attrs:{"time-picker-props":{ format: '24hr' },"date-picker-props":{ firstDayOfWeek: 1 },"date-format":"dd.MM.yyyy","time-format":"HH:mm"},model:{value:(props.item.invoicedAt),callback:function ($$v) {_vm.$set(props.item, "invoicedAt", $$v)},expression:"props.item.invoicedAt"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.invoiceEdit(props.item)}}},[_vm._v("\n                save\n              ")])],1)],1)],1):_vm._e(),(_vm.invoiceDelete && !props.item.sentAt)?_c('v-btn',{attrs:{"color":"error","dark":"","small":""},on:{"click":function () { _vm.invoiceDelete(props.item) }}},[_c('v-icon',[_vm._v("\n            delete\n          ")])],1):_vm._e()],1)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }